'use strict';
import React from "react"
import Back from "../common/back/Back"


const Ib = () => {
  return (
    <>
 <div class="div-a"></div> 
<div class="div-b"></div> 
<div class="div-c"><br/><br/><font color="white" size="5">
<h3>WHAT WE LEARN BECOMES A PART OF WHO WE ARE</h3>
<br/>
When you miss class it is your responsibility to contact me or find out what you missed. I expect all students to keep up with notes and assignments via google classroom. 
  </font>
          </div> 
        <div className='container'>
        <Back/>
          {/* <div className='col'></div> */}
          <div className='row'>
          
          </div>
        </div>
    </>
  )
}
export default Ib
