import React from "react"
import Back from "../common/back/Back"
import PriceCard from "./PriceCard"
import "./price.css"
import Faq from "./Faq"

const Pricing = () => {
  return (
    <>
 <div class="div-a"></div> 
<div class="div-b"></div> 
<div class="div-c"><br/><br/><font color="white" size="5">
<h3>NEVER STOP LEARNING, BECAUSE LIFE NEVER STOPS TEACHING</h3>
<br/>
When you miss class it is your responsibility to contact me or find out what you missed. I expect all students to keep up with notes and
​ assignments.
  </font>
          </div> 
        <div className='container'>
        <Back/>
          {/* <div className='col'></div> */}
          <div className='row'>
          
          </div>
        </div>
      {/* <Faq /> */}
    </>
  )
}

export default Pricing
