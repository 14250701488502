import React, { useState } from "react"
import { Link } from "react-router-dom"
import Head from "./Head"
import "./header.css"

const Header = () => {
  const [click, setClick] = useState(false)

  return (
    <>
      <Head />
      <header >
        <nav className='flexSB'>
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <li>
              <Link to='/'>HOME</Link>
            </li>
            <li>
              <Link to='/about'>ABOUT</Link>
            </li>
            <li>
              <Link to='/team'>FOR PARENTS</Link>
            </li>
            <li>
              <Link to='/courses'>SCH3U</Link>
            </li>
            <li>
              <Link to='/pricing'>SCH4U</Link>
            </li>
            <li>
              <Link to='/journal'>AP CHEMISTRY</Link>
            </li>
            <li>
              <Link to='/ib'>IB CHEMISTRY</Link>
            </li>
            <li>
              <Link to='/contest'>CHEMISTRY CONTEST</Link>
            </li>
            <li>
              <Link to='/contact'> CONTACT </Link>
            </li>
            
          </ul>
          {/* <div className='start'>
            <div className='button'>GET CERTIFICATE</div>
          </div> */}
          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? <i className='fa fa-times'> </i> : <i className='fa fa-bars'></i>}
          </button>
        </nav>
      </header>
    </>
  )
}

export default Header
