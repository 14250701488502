import React from "react"
import Back from "../common/back/Back"
import BlogCard from "./BlogCard"
import "./blog.css"

const Blog = () => {
  return (
    <>
     <div class="div-a"></div> 
<div class="div-b"></div> 
<div class="div-c"><br/><br/><font color="white" size="5">
<h3>EDUCATION IS OUR PASSPORT TO THE FUTURE</h3>
<br/>
I will put supplemental work on here for practice. Each night you should be studying your notes and handouts. Use your textbook as a
resource. Pay attention to when mini assessments are coming up in class.
  </font>
          </div> 
        <div className='container'>
        <Back/>
          {/* <div className='col'></div> */}
          <div className='row'>
          
          </div>
        </div>
    </>
  )
}

export default Blog
