import React from "react"
import { useLocation } from "react-router-dom"

const Back = ({ title ,subtitle}) => {
  const location = useLocation()

  return (
    <>
      <section className='back'>
        {/* <h2>Home / {location.pathname.split("/")[1]}</h2> */}
        
      </section>
     <div className='container grid'>
        <h1>{title}</h1>
     
        <p><font color="white">{subtitle}</font></p>
        </div>
      <div className='margin'></div>
    </>
  )
}

export default Back
