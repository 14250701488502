import React from "react"
// import  MsChen from './icon/MsChen.png'
const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
    
          <div className='logo'>
         
            <h1> MS CHEN CHEMISTRY CLUB<img  className="glowing-icon" src="/MsChen.png" width={55} height={55} alt="Ms Chen icon"/></h1>
           <span>    CHEMISTRY ONLINE OFFLINE EDUCATION & LEARNING</span>
          </div>

          <div className='social'>
            <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-instagram icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-youtube icon'></i>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
