'use strict';
import React from "react"
import Back from "../common/back/Back"


const Contest = () => {
  return (
    <>
 <div class="div-a"></div> 
<div class="div-b"></div> 
<div class="div-c"><br/><br/><font color="white" size="5">
<h3>WHAT A CONTEST</h3>
<br/>
   <h3>From a peek to another peek</h3>
  </font>
          </div> 
        <div className='container'>
        <Back/>
          {/* <div className='col'></div> */}
          <div className='row'>
          
          </div>
        </div>
    </>
  )
}
export default Contest