import React from "react"
import "./about.css"
import Back from "../common/back/Back"
import AboutCard from "./AboutCard"

const About = () => {
  return (
    <>
   
    <div class="div-a"></div> 
<div class="div-b"></div> 
<div class="div-c"><br/><br/><font color="white" size="5">Hello! I'm Dingna Chen, a passionate chemistry tutor dedicated to helping students excel in their studies. With a bachelor's degree in Chemistry Education and a master's degree in Inorganic Chemistry, I bring a wealth of knowledge and expertise to my teaching. Through personalized tutoring sessions on my website, I aim to make complex chemical concepts understandable and enjoyable for every student. Join me on the journey to mastering chemistry!</font>
          </div> 
        <div className='container'>
        <Back/>
          {/* <div className='col'></div> */}
          <div className='row'>
          
          </div>
        </div>
      
      {/* <AboutCard /> */}
    </>
  )
}

export default About
