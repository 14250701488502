import React from "react"
import Back from "../common/back/Back"
import TeamCard from "./TeamCard"
import "./team.css"
import Awrapper from "../about/Awrapper"
import "../about/about.css"

const Team = () => {
  return (
    <>
     <div class="div-a"></div> 
<div class="div-b"></div> 
<div class="div-c"><br/><br/><font color="white" size="6">​WE MUST REMEMBER THAT INTELLIGENCE IS NOT ENOUGH. INTELLIGENCE PLUS CHARACTER
—​THAT IS THE GOAL OF TRUE EDUCATION.</font>
          </div> 
        <div className='container'>
        <Back/>
          {/* <div className='col'></div> */}
          <div className='row'>
          
          </div>
        </div>
      {/* <Back title='FOR PARENTS' /> */}
      {/* <section className='team padding'> */}
        {/* <div className='container grid'>
          <TeamCard />
        </div> */}
      {/* </section> */}
      {/* <Awrapper /> */}
    </>
  )
}

export default Team
