import React from "react"
import Heading from "../../common/heading/Heading"
import "./Hero.css"

const Hero = () => {
  return (
    <>
      <section className='hero'>
        <div className='container'>
          <div className='row'>
            <Heading title='WELCOME TO MS CHEN CHEMISTRY CLUB' subtitle='CHILDREN MUST BE TAUGHT HOW TO THINK, NOT WHAT TO THINK"
-MARGARET MEAD' />
            <p> The best Chemistry Club to help students success in Chemistry!Already help many Students !  </p>
            <div className='button'>
              <button className='primary-btn'>
                GET STARTED NOW <i className='fa fa-long-arrow-alt-right'></i>
              </button>
              <button>
                VIEW COURSE <i className='fa fa-long-arrow-alt-right'></i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className='margin'></div>
    </>
  )
}

export default Hero
