import React from "react"
import Back from "../common/back/Back"
import CoursesCard from "./CoursesCard"
import OnlineCourses from "./OnlineCourses"

const CourseHome = () => {
  return (
    <>
      {/* <Back title='' /> */}
      <div class="div-a"></div> 
<div class="div-b"></div> 
<div class="div-c"><br/><br/><font color="white" size="5">
<h3>EDUCATION IS THE MOST POWERFUL WEAPON YOU CAN USE TO CHANGE THE WORLD</h3>
<br/>
When you miss class it is your responsibility to contact me or find out what you missed. I expect all students to keep up with notes and
​assignments.
  </font>
          </div> 
        <div className='container'>
        <Back/>
          {/* <div className='col'></div> */}
          <div className='row'>
          
          </div>
        </div>
      {/* <CoursesCard />
      <OnlineCourses /> */}
    </>
  )
}

export default CourseHome
